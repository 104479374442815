<template>
  <div v-if="theLimite" class="tlw-w-full md:tlw-w-2/5 lg:tlw-w-2/5 mr-md-3 mr-lg-3 percentage-content mb-3">
    <div :class="`percentage-number percentage-number--${numberClass}`">
      {{ leadsPercentage }}<span class="icon">%</span>
    </div>
    <div class="tlw-w-full percentage-info">
      <p class="percentage-info-title">{{ title }}</p>
      <p class="percentage-info-how-many">
        <span :class="`percentage-how-many--${styleClassMany}`">{{ theAmount }} de</span> {{ theLimite }} leads
      </p>
      <router-link v-if="linkForLeads" :to="{ name: 'LeadsManager' }" class="percentage-info-my-leads">
        Meus leads
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  prop: ['value'],
  props: {
    linkForLeads: {
      type: Boolean,
      default: false,
    },
    title: String,
    theAmount: {
      type: Number,
      default: 0,
    },
    theLimite: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleClassMany: function() {
      return parseInt(this.theAmount) >= parseInt(this.theLimite) ? 'limited' : 'default';
    },
    numberClass() {
      const percent = this.percentage(parseInt(this.theAmount), parseInt(this.theLimite));
      if (percent >= 100) {
        return 'limit';
      } else if (percent >= 50) {
        return 'half';
      } else {
        return 'default';
      }
    },
    leadsPercentage() {
      return Math.round(this.percentage(this.theAmount, this.theLimite));
    },
  },
  methods: {
    percentage(percent, total) {
      return (percent / total) * 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.percentage {
  &-content {
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    border-radius: 4px;
  }
  &-number {
    padding-right: 16px;
    font-size: 48px;
    font-weight: 700;
    line-height: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    &--default {
      color: #7427f1;
    }
    &--half {
      color: #bf8208;
    }
    &--limit {
      color: #ff305c;
    }
    .icon {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
    }
  }
  &-info {
    &-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 4px;
    }
    &-how-many {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 4px;
    }
    &-my-leads {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      text-decoration: underline;
      color: #7427f1;
      margin-bottom: 4px;
    }
  }
  &-how-many--default {
    color: #6f6f6f;
  }
  &-how-many--limited {
    color: #ff305c;
  }
}
</style>
