<template>
  <div class="tlw-w-full card-plan flex-lg-nowrap flex-md-nowrap flex-wrap mb-3" v-if="title">
    <div class="card-plan-content tlw-w-8/12">
      <img src="@/assets/images/funnel/funnels-time.svg" class="mr-4 tlw-hidden md:tlw-block" />
      <div>
        <p class="title">{{ title }}</p>
        <p class="description">
          {{ subTitle }}
        </p>
      </div>
    </div>
    <div class="tlw-flex tlw-items-center tlw-justify-center">
      <MButton
        class="pointer anchor-change-plan py-2 px-3 mt-3 m-md-0 m-lg-0"
        :label="$t('my-account.views.plan.actual-plan-panel.change-plan')"
        @click="trackPlanListViewed"
      />
    </div>
  </div>
</template>

<script>
import TrackingHelper from '@/shared/helpers/tracking';
import MButton from '@/shared/components/MButton.vue';

export default {
  props: {
    title: String,
    subTitle: String,
  },
  components: {
    MButton,
  },
  methods: {
    trackPlanListViewed() {
      const location = this.$route?.name;
      TrackingHelper.trackPlanListViewed(location);
      this.$router.push({ name: 'MyAccountPlans' });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-plan {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.card-plan-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #262626;
    margin: 4px 0px;
  }
  .description {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #6f6f6f;
    margin: 4px 0px;
  }
}
.anchor-change-plan {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #7427f1;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #7427f1;
  padding: 4px;
  text-align: center;
}
</style>
